// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-clarification-of-certain-terms-and-explanation-of-information-security-aspects-js": () => import("/src/pages/articles/clarification-of-certain-terms-and-explanation-of-information-security-aspects.js" /* webpackChunkName: "component---src-pages-articles-clarification-of-certain-terms-and-explanation-of-information-security-aspects-js" */),
  "component---src-pages-articles-specification-of-technical-report-069-js": () => import("/src/pages/articles/specification-of-technical-report-069.js" /* webpackChunkName: "component---src-pages-articles-specification-of-technical-report-069-js" */),
  "component---src-pages-articles-the-technical-report-069-js": () => import("/src/pages/articles/the-technical-report-069.js" /* webpackChunkName: "component---src-pages-articles-the-technical-report-069-js" */),
  "component---src-pages-articles-the-user-services-platform-tr-369-js": () => import("/src/pages/articles/the-user-services-platform-tr-369.js" /* webpackChunkName: "component---src-pages-articles-the-user-services-platform-tr-369-js" */),
  "component---src-pages-index-js": () => import("/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sitemap-js": () => import("/src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */)
}

